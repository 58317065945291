import { AxiosResponse } from 'axios';
import { config } from '../config/configurations';
import { instance } from './baseApi';

export function generateFromPrompt(prompt: string): Promise<AxiosResponse> {
    return instance.post(`/${config.REACT_APP_API_VERSION}/assistant/predict`, {
        prompt: prompt
    });
}

export function chat(prompt: string, history: []): Promise<AxiosResponse> {
    return instance.post(`/${config.REACT_APP_API_VERSION}/assistant/chat`, {
        prompt: prompt,
        history: history
    });
}

export function translate(text: string): Promise<AxiosResponse> {
    return instance.post(`/${config.REACT_APP_API_VERSION}/assistant/translate`, {
        sentence: text
    });
}

export function searchUsers(text: string): Promise<AxiosResponse> {
    return instance.post(`/${config.REACT_APP_API_VERSION}/assistant/users/search`, {
        user: text
    });
}

export function getRFPInfos(form: any): Promise<AxiosResponse> {
    return instance.post(`/${config.REACT_APP_API_VERSION}/assistant/process/rfp`,
        form
    );
}