import { createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "../components/authentication/protected";
import { Error404 } from "../components/login/404";
import { Homepage } from "../pages/HomePage";
import { SignInSide } from "../pages/login";

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<ProtectedRoute>
				<Homepage mode="web" />
			</ProtectedRoute>
		),
	},
	{
		path: "/login",
		element: <SignInSide />,
	},
	{
		path: "*",
		element: <Error404 />,
	},
]);
export default router;
