import { MedicalInformation, Search } from "@mui/icons-material";
import {
	Box,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { instance } from "../../apis/baseApi";
import { GlobalVariables } from "../../config/configurations";

interface DrugExtractionProps {
	setIsLoading: (isLoading: boolean) => void;
}

var globalVar = GlobalVariables.getInstance();

const capitalize = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const DrugExtraction: React.FC<DrugExtractionProps> = ({ setIsLoading }) => {
	const usageCaseStorageKey = "last_rfp_use_case_2";
	const [drugsAnswer, setdrugsAnswer] = useState<any>(
		localStorage.getItem(usageCaseStorageKey)
			? JSON.parse(localStorage.getItem(usageCaseStorageKey) || "{}")
			: null
	);
	const integration = globalVar.getIntegrationMode();
	const [apiCall] = useState<string>(
		integration === "web" ? "/v1/identify/bpu" : "/v1/addin/identify/bpu"
	);

	const cleanExcel = async () => {
		await Excel.run(async (context: any) => {
			const currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
			const lastRow = currentWorksheet.getUsedRange().getLastRow();
			lastRow.load("rowIndex"); // Load the rowIndex property of the last row

			await context.sync(); // Sync to get the last row's rowIndex

			const endRow = lastRow.rowIndex;
			const rangeAddress = `G6:I${endRow}`;
			const range = currentWorksheet.getRange(rangeAddress);

			// Load the background color and values of the range
			range.load(["values", "format/fill/color"]);
			await context.sync();
			const cells = range.getCellProperties({ format: { fill: { color: true } } });
			await context.sync();
			// Define the orange color in hexadecimal (e.g., "#FFA500" for orange)
			const orangeColor = "#FFA500".toLowerCase();

			// Loop through the range and check the fill color
			const values = range.values;

			for (let i = 0; i < values.length; i++) {
				for (let j = 0; j < values[i].length; j++) {
					const cellColor = cells.value[i][j].format.fill.color;
					range.getCell(i, j).values = [[""]]; // Clear the value
					if (cellColor && cellColor.toLowerCase() === orangeColor) {
						range.getCell(i, j).format.fill.clear(); // Clear the fill color
					}
				}
			}

			await context.sync();
		});
	};

	const fillExcel = async () => {
		await Excel.run(async (context: any) => {
			const currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
			if (drugsAnswer === null || Object.keys(drugsAnswer).length === 0) {
				return;
			}
			drugsAnswer?.drugs.map(async (el: any) => {
				if (el?.drug?.production_address === null || el?.drug?.production_address === "") {
					currentWorksheet.getRange(`G${el.row + 1}`).format.fill.color = "orange";
				} else {
					currentWorksheet.getRange(`G${el.row + 1}`).values = [
						[el.drug.production_address.join("\n\n")],
					];
				}
				if (el?.drug?.vendor_address === null || el?.drug?.vendor_address === "") {
					currentWorksheet.getRange(`H${el.row + 1}`).format.fill.color = "orange";
				} else {
					currentWorksheet.getRange(`H${el.row + 1}`).values = [
						[el.drug.vendor_address.join("\n\n")],
					];
				}
				/*
				if (el?.drug?.iso === null || el?.drug?.iso === "") {
					currentWorksheet.getRange(`I${el.row + 1}`).format.fill.color = "orange";
				} else {
				*/
				var range = currentWorksheet.getRange(`I${el.row + 1}`);
				range.values = [
					[
						[
							"ISO 50001:2018, 08 September 2022 until 07 September 2025",
							"ISO 14001:2015, 08 September 2022 until 07 September 2025",
						].join("\n\n"),
					],
				];
				// }
				await context.sync();
			});
		});
	};

	const extractDrugFromFile = async () => {
		setIsLoading(true);
		await Excel.run(async (context: any) => {
			await context.sync();
			const currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
			var range = currentWorksheet.getUsedRange();
			range.load("values");
			context
				.sync()
				.then(() => {
					const values = range.values;
					var fd = null;
					if (integration === "web") {
						fd = new FormData();
						fd.append("rfps", new Blob([JSON.stringify(values)]));
					} else if (integration === "office-add-in") {
						fd = values;
					}
					instance
						.post(apiCall, fd)
						.then((response) => {
							if (response.status === 200) {
								setdrugsAnswer(response.data);
								localStorage.setItem(
									usageCaseStorageKey,
									JSON.stringify(response.data)
								);
							}
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							setIsLoading(false);
						});
				})
				.catch((error: any) => {
					console.log(error);
				});
		});
	};

	return (
		<>
			{drugsAnswer && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						p: 2,
						overflowY: "overlay",
					}}
				>
					<Typography variant="h5" sx={{ mb: 2 }}>
						Drug Extracted
					</Typography>
					<Typography variant="body1" sx={{ mb: 2 }}>
						Extracted {(drugsAnswer as any)?.count} / {(drugsAnswer as any)?.total}{" "}
						drugs
					</Typography>
					<List>
						{(drugsAnswer as any)?.drugs?.map((el: any, index: number) => (
							<ListItem
								key={index}
								sx={{
									display: "flex",
									flexDirection: "row",
									width: "100%",
								}}
								alignItems="flex-start"
							>
								<ListItemButton
									sx={{
										p: 1,
										height: "100%",
										verticalAlign: "top !important",
									}}
								>
									<MedicalInformation />
								</ListItemButton>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
										justifyContent: "center",
										width: "100%",
										ml: 2,
									}}
								>
									<List
										sx={{
											p: 1,
											mt: "-4px",
										}}
									>
										<ListItemText
											primary={`Lot (${el?.drug?.num_lot}.${
												el?.drug?.num_sub_lot
											}): ${capitalize(el?.drug?.denomination)}`}
											secondary={
												<Box>
													<Typography variant="body2">
														Format: <i>{el?.drug?.sl}</i>
													</Typography>
													<Typography variant="body2">
														Volume:{" "}
														<b>
															{el?.drug?.volume
																? el?.drug?.volume
																: "No volume provided"}
														</b>
													</Typography>
												</Box>
											}
										/>
									</List>
								</Box>
							</ListItem>
						))}
					</List>
				</Box>
			)}
			{!drugsAnswer && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						p: 2,
					}}
				>
					<Typography variant="h5" sx={{ mb: 2 }}>
						Drug Extracted
					</Typography>
					<Typography variant="body1" sx={{ textAlign: "center" }}>
						No drug extracted yet
					</Typography>
					<Button
						variant="contained"
						color="primary"
						sx={{ mt: 2 }}
						onClick={() => {
							localStorage.removeItem(usageCaseStorageKey);
							setdrugsAnswer(null);
							extractDrugFromFile();
						}}
						endIcon={<Search />}
					>
						Launch Drug Extraction
					</Button>
				</Box>
			)}
			{drugsAnswer && (
				<>
					<Button
						variant="contained"
						color="primary"
						sx={{ m: 3, mb: 1 }}
						onClick={() => {
							fillExcel();
						}}
					>
						Fill
					</Button>
					<Button
						variant="contained"
						color="primary"
						sx={{ m: 3, mt: 1, backgroundColor: "#AA4A44" }}
						onClick={() => {
							cleanExcel();
							localStorage.removeItem(usageCaseStorageKey);
							setdrugsAnswer(null);
						}}
					>
						Clear
					</Button>
				</>
			)}
		</>
	);
};
