import {
	ArrowBackIos as ArrowBackIosIcon,
	Close as CloseIcon,
	ExitToApp as ExitToAppIcon,
	Groups as GroupsIcon,
	Menu as MenuIcon,
	Mic as MicIcon,
	Search as SearchIcon,
	Send as SendIcon,
	SettingsAccessibility as SettingsAccessibilityIcon,
	Settings as SettingsIcon,
} from "@mui/icons-material";
import {
	AppBar,
	Box,
	Button,
	CircularProgress,
	Container,
	Divider,
	Drawer,
	IconButton,
	InputBase,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Snackbar,
	Toolbar,
	Tooltip,
	Typography,
	alpha,
	styled,
} from "@mui/material";
import React, { useState } from "react";
import { forceLogout } from "../../apis/authentication";
import { GlobalVariables } from "../../config/configurations";
import { cleanMeetingFromLocalStorage } from "../../helpers/localStorage";

import "../media/header/meetingMenu.css";
import "../media/header/menuBar.css";

const StyledSearch = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.primary.main, 0.15),
	"&:hover": {
		backgroundColor: alpha(theme.palette.primary.main, 0.25),
	},
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: "100%",
	[theme.breakpoints.up("sm")]: {
		marginLeft: theme.spacing(3),
		width: "auto",
	},
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			width: "20ch",
		},
	},
}));

//search as JSX
const search = (
	<StyledSearch>
		<SearchIconWrapper>
			<SearchIcon />
		</SearchIconWrapper>
		<StyledInputBase placeholder="Search" inputProps={{ "aria-label": "search" }} />
	</StyledSearch>
);

var globalVar = GlobalVariables.getInstance();

interface TopBarHeaderProps {
	title?: string;
	subtitle?: string;
	children?: React.ReactNode;
	mode?: "addon" | "standalone" | "rfp" | "";
}

export const TopBarHeader: React.FC<TopBarHeaderProps> = (props: TopBarHeaderProps) => {
	const [open, setState] = useState(false);
	const [displaySnackbar, setDisplaySnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarType, setSnackbarType] = useState<
		"success" | "error" | "warning" | "info" | undefined
	>("success");
	const [isNewMeetingLoading, setIsNewMeetingLoading] = useState(false);

	const hangleNewVoiceNote = (name?: string, description?: string) => {
		if (!name) {
			var date = new Date();
			name = `New meeting - ${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
		}
		setIsNewMeetingLoading(true);
	};

	const toggleDrawer = (open: boolean) => (event: any) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		//changes the function state according to the value of open
		setState(open);
	};

	return (
		<AppBar
			position="static"
			sx={{
				width: "100%",
				backgroundColor: "white",
				color: "primary.main",
				height: "60px",
			}}
		>
			<Container
				sx={{
					width: "100%",
					maxWidth: "100% !important",
					p: "0 !important",
				}}
			>
				<Toolbar>
					{!localStorage.getItem("meetingId") && (
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={toggleDrawer(true)}
							sx={{
								mr: 1,
								display: {
									xs: "block",
								},
								margin: "auto",
								marginLeft: "0px",
							}}
						>
							<MenuIcon />
						</IconButton>
					)}
					{localStorage.getItem("meetingId") && (
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={() => {
								cleanMeetingFromLocalStorage();
								if (globalVar.getIntegrationMode() === "addon") {
									window.location.href = "/index.html#";
								} else {
									window.location.href = "/";
								}
							}}
							sx={{
								mr: 1,
								display: {
									xs: "block",
								},
								width: "30px !important",
								margin: "auto",
								marginLeft: "-10px",
								marginRight: "-34px",
								".MuiButton-startIcon": {
									fontSize: "small",
								},
							}}
						>
							<ArrowBackIosIcon />
						</IconButton>
					)}
					{props.title && (
						<Box
							sx={{
								flexGrow: 1,
								display: {
									sm: "block",
									mr: 1,
								},
							}}
						>
							<Typography
								noWrap
								component="p"
								sx={{
									textAlign: "center",
									verticalAlign: "middle",
									margin: "auto",
								}}
							>
								{props.title}
							</Typography>
							{props.subtitle && (
								<Typography
									noWrap
									component="p"
									sx={{
										fontSize: "11px",
										color: "#bababa",
										textAlign: "center",
										verticalAlign: "middle",
										margin: "auto",
									}}
								>
									{props.subtitle}
								</Typography>
							)}
						</Box>
					)}
					{props?.mode !== "rfp" && !localStorage.getItem("meetingId") && (
						<Box
							sx={{
								display: {
									xs: "block",
								},
							}}
						>
							<Tooltip title="New meeting recording" placement="bottom">
								<IconButton
									sx={{ margin: "8px", mx: "auto", padding: "12px" }}
									onClick={() => hangleNewVoiceNote()}
								>
									{!isNewMeetingLoading && (
										<MicIcon fontSize="small" sx={{ color: "primary.main" }} />
									)}
									{isNewMeetingLoading && (
										<CircularProgress
											size={20}
											sx={{ color: "primary.main" }}
										/>
									)}
								</IconButton>
							</Tooltip>
						</Box>
					)}
					{props.children}

					{/* The outside of the drawer */}
					<Drawer
						//from which side the drawer slides in
						anchor="left"
						//if open is true --> drawer is shown
						open={open}
						//function that is called when the drawer should close
						onClose={toggleDrawer(false)}
						sx={{}}
						//function that is called when the drawer should open
					>
						{/* The inside of the drawer */}
						<Box
							sx={{
								p: 2,
							}}
						>
							{/* 
                  when clicking the icon it calls the function toggleDrawer 
                  and closes the drawer by setting the variable open to false
                  */}
							<IconButton sx={{ mb: 2 }} onClick={toggleDrawer(false)}>
								<CloseIcon />
							</IconButton>

							<Divider sx={{ mb: 2 }} />

							{search}
							<Divider sx={{ mt: 2 }} />
							<Box sx={{ mb: 2 }}>
								<ListItemButton
									onClick={() => {
										cleanMeetingFromLocalStorage();
										if (globalVar.getIntegrationMode() === "addon") {
											window.location.href = "/index.html#";
										} else {
											window.location.href = "/";
										}
									}}
								>
									<ListItemIcon>
										<MicIcon sx={{ color: "primary.main" }} />
									</ListItemIcon>
									<ListItemText primary="Homepage" />
								</ListItemButton>
								<ListItemButton>
									<ListItemIcon>
										<MicIcon sx={{ color: "primary.main" }} />
									</ListItemIcon>
									<ListItemText primary="New vocal notes" />
								</ListItemButton>
								<ListItemButton onClick={() => hangleNewVoiceNote}>
									<ListItemIcon>
										<GroupsIcon sx={{ color: "primary.main" }} />
									</ListItemIcon>
									<ListItemText primary="New meeting" />
								</ListItemButton>
							</Box>

							<Box
								sx={{
									display: "block",
									width: "100%",
									justifyContent: "center",
									position: "absolute",
									bottom: "30px",
									left: "50%",
									transform: "translate(-50%, 0)",
								}}
							>
								<ListItemButton>
									<Divider sx={{}} />
									<ListItemIcon>
										<SettingsIcon sx={{ color: "primary.main" }} />
									</ListItemIcon>
									<ListItemText primary="My Settings" />
								</ListItemButton>

								<ListItemButton>
									<ListItemIcon>
										<SettingsAccessibilityIcon sx={{ color: "primary.main" }} />
									</ListItemIcon>
									<ListItemText primary="My personal information" />
								</ListItemButton>
								<ListItemButton>
									<ListItemIcon>
										<SendIcon sx={{ color: "primary.main" }} />
									</ListItemIcon>
									<ListItemText primary="Need a feature ?" />
								</ListItemButton>
								<Divider sx={{}} />
								<Box sx={{ width: "100%", height: "37px", display: "flex" }}>
									<Button
										variant="text"
										sx={{ margin: "8px", mx: "auto", padding: "12px" }}
										startIcon={<ExitToAppIcon />}
										onClick={() => {
											forceLogout();
										}}
									>
										Logout
									</Button>
								</Box>
								<Box
									sx={{
										width: "100%",
										height: "10px",
										display: "block",
										py: 1,
										justifyContent: "center",
										fontSize: "10px",
										color: "#bababa",
									}}
								>
									<Typography sx={{ fontSize: "10px", textAlign: "center" }}>
										Version 1.0.0
									</Typography>
									<Typography sx={{ fontSize: "10px", textAlign: "center" }}>
										Powered by SecondBrain © {new Date().getFullYear()}{" "}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Drawer>
				</Toolbar>
			</Container>
			<Snackbar
				open={displaySnackbar}
				onClose={() => setDisplaySnackbar(false)}
				autoHideDuration={4000}
				sx={{
					borderRadius: "10px",
					minWidth: "50%",
					borderColor: snackbarType === "success" ? "primary.main" : "#870202",
					backgroundColor: snackbarType === "success" ? "primary.main" : "#870202",
					color: snackbarType === "success" ? "black" : "white",
				}}
			>
				<Box
					sx={{
						width: "100%",
						backgroundColor: snackbarType === "success" ? "primary.main" : "#870202",
						borderRadius: "10px 10px 0px 0px",
						display: "flex",
						margin: "auto",
					}}
				>
					<Typography
						variant="body1"
						sx={{
							color: snackbarType === "success" ? "black" : "white",
							fontSize: "16px",
							textAlign: "center",
							p: "10px",
						}}
					>
						{snackbarMessage}
					</Typography>
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						sx={{
							position: "absolute",
							right: "10%",
							transform: "translate(100%, 25%)",
						}}
						onClick={() => setDisplaySnackbar(false)}
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</Box>
			</Snackbar>
		</AppBar>
	);
};
