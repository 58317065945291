import {
	AutoFixHighOutlined as AutoFixHighOutlinedIcon,
	BrandingWatermark as BrandingWatermarkIcon,
	NoteAltOutlined as NoteAltOutlinedIcon,
} from "@mui/icons-material";

export const tabList: any = {
	Excel: [
		{
			id: "summary",
			title: "Summary",
			icon: <BrandingWatermarkIcon />,
			subtitle: "Summary of your document",
		},
		/*
        {
            id: "setup",
            title: "Setup",
            icon: <SettingsApplicationsIcon />,
            subtitle: "Active sheet setup"
        },
        */
		{
			id: "assistant",
			title: "Assistant",
			icon: <AutoFixHighOutlinedIcon />,
			subtitle: "Can I help you ?",
		},
	],
	Word: [
		{
			id: "summary",
			title: "Summary",
			icon: <BrandingWatermarkIcon />,
			subtitle: "Summary of your document",
		},
		{
			id: "qna",
			title: "Q&A",
			icon: <NoteAltOutlinedIcon />,
			subtitle: "RFP Q&A assistant",
		},
		{
			id: "assistant",
			title: "Assistant",
			icon: <AutoFixHighOutlinedIcon />,
			subtitle: "Can I help you ?",
		},
	],
};
