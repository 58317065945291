// @ts-nocheck

import { Box, Card, CardContent, CircularProgress, CardMedia, IconButton, Typography } from "@mui/material";
import { chat } from "../../../apis/assistant";
import { useEffect, useRef, useState } from "react";
import { Send as SendIcon } from "@mui/icons-material";
import Markdown from 'react-markdown'

function getLinkPreviewInfo(url: string) {
    const fetchData = async () => {
        try {
            const response = await fetch(url);
            const data = await response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, 'text/html');
            const title = doc.querySelector('title')?.textContent || '';
            const description = doc.querySelector('meta[name="description"]')?.getAttribute('content') || '';
            const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || '';

            return { title, description, image, url };
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    return fetchData();
}

export const Prediction = (props: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [prediction, setPrediction] = useState<any>(null);
    const [linkPreviews, setLinkPreviews] = useState<[]>([]);

    useEffect(() => {
        setIsLoading(true);
        /*
        if (props.messages[i].message === "Please wait a moment while I generate a summary of your meeting.") {
            setPrediction(null);
            setLinkPreviews([]);
            setIsLoading(true);
            return;
        }
        */

    }, []);

    useEffect(() => {
        if (!isLoading) {
            return;
        }
        var inMemoryArray = []
        props.messages.toReversed().map((m: any, index: number) => {
            let lookAtMsg = ""
            if (inMemoryArray.length >= 6 || !m.inMemory) {
                return
            }
            if (m.type === "bot") {
                lookAtMsg = m.generatedMessage;
            } else {
                lookAtMsg = m.message;
            }
            if (lookAtMsg === props.sentence) {
                return 
            }

            inMemoryArray.push({
                "author": m.type,
                "content": m.type === "bot" ? m.generatedMessage : m.message,
            });

        });
        chat(props.sentence, inMemoryArray).then((res: any) => {
            props.setBotMessageCallback(res.data.candidates[0].content);
            setPrediction(res.data);
            setLinkPreviews(
                res.data.citationMetadata[0].citations.map((citation: any) => {
                    var values = getLinkPreviewInfo(citation.url);
                    if (values) {
                        return values;
                    }
                })
            );
            setIsLoading(false);
        }).catch((err: any) => {
            console.log(err);
            setIsLoading(false);
        });
    }, [isLoading]);


    return (
        <>
            <Box
                sx={{
                    backgroundColor: prediction?.safetyAttributes[0].blocked ? "#848484" : "transparent",
                    fontSize: "13.3333px",
                    fontWeight: "400",
                    color: "white",
                    px: "14px",
                    py: "10px",
                    borderRadius: "5px",
                }}
            >
                {isLoading && <Box sx={{ display: "flex" }}><CircularProgress sx={{ margin: "auto" }} color="inherit" /></Box>}
                {prediction && prediction.safetyAttributes[0].blocked &&
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                fontSize: "13.3333px",
                                textAlign: "justify",
                                fontStyle: "italic",
                                fontWeight: "bold",
                                margin: "auto",
                            }}
                        >
                            Oops ... Your content has been blocked because it contains sensitive information.
                        </Typography>
                    </Box>
                }
                {prediction && !prediction.safetyAttributes[0].blocked &&
                    <>
                        <Typography
                            sx={{
                                fontSize: "13.3333px",
                                textAlign: "justify",
                            }}
                        >
                            <Markdown skipHtml={true}>
                                {prediction.candidates[0].content}
                            </Markdown>
                        </Typography>
                        <Box>
                            {linkPreviews.map((preview: any, index:number   ) => {
                                if (!preview || !preview.title || !preview.url) {
                                    return null;
                                }
                                return (
                                    <Card ref={index} sx={{ maxWidth: 345, display: "flex" }}>
                                        <CardMedia
                                            component="img"
                                            height="194"
                                            image={preview.image}
                                            alt={preview.title}
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                {preview.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {preview.description}
                                            </Typography>
                                            <IconButton aria-label="Go" onClick={
                                                () => {
                                                    window.open(preview.url, '_blank');
                                                }
                                            }>
                                                <SendIcon />
                                            </IconButton>
                                        </CardContent>
                                    </Card>
                                )
                            })}
                        </Box>
                    </>
                }
            </Box >
        </>
    );
}