export function cleanLocalStorage() {
    localStorage.clear();
}

export function cleanLocalStorageExceptInit() {
    var backend_instance = localStorage.getItem("backend_instance");
    localStorage.clear();
    localStorage.setItem("backend_instance", backend_instance || "");
}

export function cleanMeetingFromLocalStorage() {
    localStorage.removeItem("meetingId");
    localStorage.removeItem("transcriptId");
    localStorage.removeItem("noteId");
    localStorage.removeItem("novel__content");
    localStorage.removeItem("meetingInfo");
    localStorage.removeItem("user");
}