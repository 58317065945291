import React from "react";

import { Box } from "@mui/material";

import { Assistant } from "../../../components/assistant-layout";
import { IRfpProps } from "../../../interfaces/IRFP";

import { DrugExtraction } from "../../../components/drug-layout";
import "../homepage.css";

export const Homepage: React.FC<IRfpProps> = (props: IRfpProps) => {
	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "calc(100% - 58px)",
					display: "flex",
					flexDirection: "column",
				}}
			>
				{props.selectedPage === "summary" && (
					<DrugExtraction setIsLoading={props.setIsLoading} />
				)}
				{props.selectedPage === "assistant" && <Assistant mode={props.mode} />}
			</Box>
		</>
	);
};
