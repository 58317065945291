import { CssBaseline, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";

import "./App.css";
import { customTheme } from "./components/palette";
import { GlobalVariables } from "./config/configurations";

import { LoginProvider } from "./components/login/loginContext";
import router from "./routes/index";

var globalVar = GlobalVariables.getInstance();
globalVar.setIntegrationMode("office-add-in");

function App() {
	return (
		<LoginProvider>
			<ThemeProvider theme={customTheme}>
				<CssBaseline />
				<RouterProvider router={router} />
			</ThemeProvider>
		</LoginProvider>
	);
}

export default App;
